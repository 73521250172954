import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import Button, { ButtonProps } from '@mui/material/Button'
import clsx from 'clsx'

interface Props extends ButtonProps {
  active: boolean
  translationKey: string
  defaultTranslation: string
}

const useStyles = makeStyles(({ palette }) => ({
  root: {
    margin: '20px 0 5px',
    padding: '10px 16px',
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 8,
    color: palette.mode === 'dark' ? 'rgba(255, 255, 255)' : 'rgba(0, 0, 0, 0.87)',
    backgroundColor: palette.mode === 'dark' ? '#5D5E62' : '#E3E6ED',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: palette.mode === 'dark' ? '#4C4C4F' : '#CDD0D7',
    },
    '&:active': {
      backgroundColor: palette.mode === 'dark' ? '#666667' : '#D7D7D7',
    },
  },
}))

const DownloadButton: FC<Props> = ({ active, translationKey, defaultTranslation, ...props }) => {
  const classes = useStyles()
  return (
    <Button classes={classes} className={clsx({ active })} {...props}>
      <Trans i18nKey={translationKey} defaults={defaultTranslation} />
    </Button>
  )
}

export default DownloadButton
