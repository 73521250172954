import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { AppBar } from '@mui/material'
import { DefaultTheme, makeStyles } from '@mui/styles'
import clsx from 'clsx'

import { TopMenuInfoSection } from './TopMenuInfoSection'
import { Logo } from '../Logo'
import { backToDashboardHandle, isMobile } from '../../utils'
import { activeTabSelector } from '../../store/selectors'
import { MenuItemsNames } from '../pages/menuBarList'
import { BackButtonMobile } from '../mobile/BackButtonMobile'
import { mobileBackButtonDataSelector } from '../../store/selectors'
import BentoMenu from '../BentoMenu'
import DepositButton from '../DepositButton'

const useStyles = makeStyles<DefaultTheme, { morePage: boolean }, string>((theme) => ({
  mainLogoDesktop: {
    minWidth: 'fit-content',
  },
  mainLogoMobile: {
    display: 'flex',
    marginTop: '-3px',
  },
  mainLogo: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    cursor: 'pointer',
    // [theme.breakpoints.down('md')]: {
    //   display: 'none',
    // },
  },
  appBarContainer: {
    fontSize: '1.2em',
    height: isMobile() ? '60px' : '66px',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.action.selected}`,
  },
  barContent: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'inherit',
  },
  // bentoPlaceholder: {
  //   display: 'inline-block',
  //   backgroundColor: '#BBBBBB',
  //   width: 50,
  //   height: 50,
  //   margin: '8px',
  // },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  barContentLeftSideWrap: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
    '&.mobile': {
      marginLeft: '0px',
      height: '100%',
      width: ({ morePage }) => {
        return morePage ? 'auto' : '100%'
      },
      marginRight: 0,
    },
  },
}))

export const TopMenu: FC = () => {
  const isMobileDevice = isMobile()
  const activeTab = useSelector(activeTabSelector)
  const backButtonData = useSelector(mobileBackButtonDataSelector)
  const isMobileBackButtonVisible = (backButtonData?.show || false) && isMobileDevice
  const mobileBackButtonTitle = backButtonData?.title || ''
  const classes = useStyles({ morePage: activeTab === MenuItemsNames.more })

  return (
    <AppBar className={classes.appBarContainer} position={'static'} color={'transparent'}>
      <div className={clsx(classes.barContent, activeTab === MenuItemsNames.more ? classes.justifySpaceBetween : '')}>
        {(!isMobileDevice || (activeTab === MenuItemsNames.more && !isMobileBackButtonVisible)) && (
          <div className={clsx(classes.barContentLeftSideWrap, isMobileDevice ? 'mobile' : '')}>
            <div
              className={[classes.mainLogo, isMobile() ? classes.mainLogoMobile : classes.mainLogoDesktop].join(' ')}
              onClick={backToDashboardHandle.bind(null)}
            >
              <Logo variant={'header'} />
            </div>
            {!isMobileDevice && <BentoMenu />}
            {/* <div className={classes.bentoPlaceholder}>Bento</div> */}
            {!isMobileDevice && <DepositButton />}
          </div>
        )}
        {!isMobileBackButtonVisible ? (
          <div className={clsx(classes.barContentLeftSideWrap, isMobileDevice ? 'mobile' : '')}>
            {isMobileDevice && <BentoMenu />}
            <TopMenuInfoSection />
          </div>
        ) : (
          <BackButtonMobile show={isMobileBackButtonVisible} title={mobileBackButtonTitle} />
        )}
      </div>
    </AppBar>
  )
}
