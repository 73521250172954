import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import clsx from 'clsx'

import BentoMenuListIcon from './BentoMenuListIcon'
import { getMenuItemsForClient } from './utils'
import { accountsDataSelector, userCompanySelector, userWhiteLabelSelector } from '../../store/selectors'

const BentoMenuListWrap = styled('div')(({ theme }) => ({
  width: 320,
  padding: '10px 0',
  [theme.breakpoints.down('md')]: {
    width: 280,
  },
}))

const BentoMenuGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  margin: 0,
  '& .MuiGrid-root': {
    padding: '10px 18px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px',
    },
    '&:hover': {
      background: theme.palette.mode === 'dark' ? '#1F1F1F' : '#FAFAFA',
    },
  },
  '& .MuiGrid-item': {
    [theme.breakpoints.up('md')]: {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  '&.divider': {
    padding: 0,
  },
}))

const BentoDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#EBEBEB',
  margin: '8px 0',
  padding: 0,
}))

const BentoMenuListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: 8,
  transition: '0.2s',
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: 0,
  },
}))

const BentoMenuListTitle = styled('div')(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  fontSize: 14,
  paddingLeft: 14,
}))

const BentoMenuListSubtitle = styled('div')({
  paddingLeft: 34,
  fontSize: 12,
  letterSpacing: '0.4px',
  color: '#858585',
})

interface Props {
  onClose: () => void
}

const BentoMenuList: FC<Props> = ({ onClose }) => {
  const accounts = useSelector(accountsDataSelector)
  const company = useSelector(userCompanySelector)
  const whiteLabel = useSelector(userWhiteLabelSelector)

  return (
    <BentoMenuListWrap>
      <BentoMenuGrid container spacing={2}>
        {getMenuItemsForClient({ accounts, company, whiteLabel })?.map((item) => (
          <BentoMenuGrid
            className={clsx({ divider: item.id === 'Divider' })}
            md={item.id === 'Divider' ? 12 : 6}
            xs={12}
            key={item.id}
            flexDirection={'column'}
            item
          >
            {item.id === 'Divider' ? (
              <BentoDivider />
            ) : (
              <BentoMenuListItem
                onClick={() => {
                  item?.handle?.()
                  onClose?.()
                }}
              >
                <Box display={'flex'} flexDirection={'column'}>
                  <Box display={'flex'} flexDirection={'row'}>
                    <BentoMenuListIcon id={item.id} />
                    <BentoMenuListTitle>
                      <Trans
                        i18nKey={item.title.translationKey}
                        values={item.title.translationValues}
                        defaults={item.title.defaultTranslation}
                      />
                    </BentoMenuListTitle>
                  </Box>
                  {item?.subtitle && (
                    <BentoMenuListSubtitle>
                      <Trans
                        i18nKey={item.subtitle.translationKey}
                        values={item.title.translationValues}
                        defaults={item.subtitle.defaultTranslation}
                      />
                    </BentoMenuListSubtitle>
                  )}
                </Box>
              </BentoMenuListItem>
            )}
          </BentoMenuGrid>
        ))}
      </BentoMenuGrid>
    </BentoMenuListWrap>
  )
}

export default BentoMenuList
