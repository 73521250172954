import React, { FC, FunctionComponent } from 'react'
import { styled } from '@mui/system'

import { MenuItemIndices } from './types'
import { ReactComponent as BentoMenuDashboardItemIcon } from './assets/bentoMenuDashboardItem.svg'
import { ReactComponent as BentoMenuAffiliatePortalItemIcon } from './assets/bentoMenuAffiliatePortalItem.svg'
import { ReactComponent as BentoMenuWebsiteItemIcon } from './assets/bentoMenuWebsiteItem.svg'
import { ReactComponent as BentoMenuIBPortalItemIcon } from './assets/bentoMenuIBPortalItem.svg'
import { ReactComponent as BentoMenuTelegramItemIcon } from './assets/bentoMenuTelegramChannelItem.svg'
import { ReactComponent as BentoMenuTradingCentralItemIcon } from './assets/bentoMenuTradingCentralItem.svg'
import { ReactComponent as BentoMenuMobileAppItemIcon } from './assets/bentoMenuMobileAppItem.svg'

export const iconsComponentsMap: Record<MenuItemIndices, FunctionComponent & { title?: string | undefined }> = {
  Dashboard: BentoMenuDashboardItemIcon,
  AffiliatePortal: BentoMenuAffiliatePortalItemIcon,
  Website: BentoMenuWebsiteItemIcon,
  IBPortal: BentoMenuIBPortalItemIcon,
  TelegramChannel: BentoMenuTelegramItemIcon,
  TradingCentral: BentoMenuTradingCentralItemIcon,
  MobileApp: BentoMenuMobileAppItemIcon,
}

const BentoMenuListIconWrap = styled('div')(({ theme }) => ({
  display: 'inline',
  verticalAlign: 'text-bottom',
  '& svg': {
    fill: theme.palette.mode === 'light' ? '#000' : '#fff',
  },
}))

export const BentoMenuListIcon: FC<{ id: MenuItemIndices }> = ({ id }) => {
  const Icon = iconsComponentsMap[id]
  return (
    <BentoMenuListIconWrap>
      <Icon />
    </BentoMenuListIconWrap>
  )
}

export default BentoMenuListIcon
