import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import QRCode from 'react-qr-code'
import { Trans } from 'react-i18next'
import { styled } from '@mui/system'
import { useTheme } from '@mui/styles'
import { Divider, Typography } from '@mui/material'

import DownloadButton from './DownloadButton'
import { getDownloadAppLinks } from '../BentoMenu/utils'
import { trackEvent } from '../../analytics/firebaseLogger'
import { setShowDownloadMobileAppDialog } from '../../store/actions'
import { isAndroid, isIos, isMobile, openUrlHandle } from '../../utils'
import { Dialog as MuiDialog, DialogContent, DialogTitle } from '../Dialog'
import { MobileAppLinksIndices, MobileAppLoadOptionType } from '../../config'
import { onQRCodeButtonClicked, onQRCodePopupPresented } from '../../analytics/events'
import { getShowDownloadMobileAppDialog, userCompanyLabelSelector } from '../../store/selectors'

import { ReactComponent as DownloadAndroidApkIcon } from './assets/downloadAndroidApk.svg'
import { ReactComponent as DownloadFromGooglePlayIcon } from './assets/downloadFromGooglePlay.svg'

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.7)',
  },
  '& .MuiDialog-paper': {
    width: isMobile() ? 344 : 280,
    margin: 'auto',
    padding: 24,
    boxShadow: 'none',
    borderRadius: 8,
    background: theme.palette.mode === 'dark' ? '#1F1F1F' : theme.palette.background.paper,
  },
  '& .MuiDialogTitle-root': {
    width: '100%',
    padding: isMobile() ? '24px 0' : 0,
    textAlign: 'center',
    fontSize: isMobile() ? 16 : 14,
  },
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
}))

const QRCodeWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#EBEBEB',
  borderRadius: 8,
  margin: '20px 0',
  '& svg': {
    width: '100%',
    height: '100%',
    padding: '25px',
  },
}))

const DividerWrap = styled('div')({
  position: 'relative',
})

const DividerLine = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#EBEBEB',
  margin: '10px 0',
  [theme.breakpoints.down('md')]: {
    margin: '4px 0',
  },
}))

const DividerText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  zIndex: 1,
  padding: '0 15px',
  fontSize: 14,
  lineHeight: 1,
  backgroundColor: theme.palette.mode === 'dark' ? '#1F1F1F' : '#FFFFFF',
}))

const DownloadButtonsWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 40,
  '& a:first-of-type': {
    margin: 10,
  },
  '& a:last-of-type': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: 120,
    margin: 8,
    textDecoration: 'none',
    color: 'inherit',
  },
})

const downloadLinks = getDownloadAppLinks()

const downloadButton = {
  androidApk: {
    sourceKey: 'androidApkFile',
    translationKey: 'androidApk',
    defaultTranslation: 'Android APK',
  },
  storeLink: {
    sourceKey: 'autoDetectStore',
    translationKey: 'downloadApp',
    defaultTranslation: 'App Store or Google Play',
  },
}

const storeName = () => {
  switch (true) {
    case isIos():
      return 'App Store'
    case isAndroid():
      return 'Google Play'
    default:
      return ''
  }
}

const DownloadMobileAppDialog: FC = () => {
  const [activeSource, setActiveSource] = useState<MobileAppLinksIndices>('autoDetectStore')
  const [downloadOption, setDownloadOption] = useState<MobileAppLoadOptionType>('androidApk')
  const dispatch = useDispatch()
  const showDownloadMobileDialog = useSelector(getShowDownloadMobileAppDialog)
  const userCompanyLabel = useSelector(userCompanyLabelSelector)
  const theme = useTheme()

  useEffect(() => {
    if (showDownloadMobileDialog) {
      setDownloadOption('androidApk')
    }
  }, [showDownloadMobileDialog])

  useEffect(() => {
    showDownloadMobileDialog &&
      trackEvent(onQRCodePopupPresented, {
        brand: userCompanyLabel,
        type: isMobile() ? 'links' : 'QR',
        source: (() => {
          switch (true) {
            case isIos():
              return 'iOS'
            case isAndroid():
              return 'Android'
            default:
              return 'Desktop'
          }
        })(),
      })
  }, [showDownloadMobileDialog, userCompanyLabel])

  const closeDialogHandle = useCallback(() => {
    dispatch(setShowDownloadMobileAppDialog(false))
  }, [dispatch])

  const clickDownloadButtonHandle = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (!isMobile()) {
        const key = e.currentTarget.dataset?.key
        key && setActiveSource(key as MobileAppLinksIndices)
        setDownloadOption((prevOption) => (prevOption === 'androidApk' ? 'storeLink' : 'androidApk'))
      } else {
        e.preventDefault()
        openUrlHandle(e.currentTarget.getAttribute('href') as string)
      }

      trackEvent(onQRCodeButtonClicked, {
        brand: userCompanyLabel,
        type: isMobile() ? 'links' : 'QR',
        source: (() => {
          switch (true) {
            case isIos():
              return 'iOS'
            case isAndroid():
              return 'Android'
            default:
              return 'Desktop'
          }
        })(),
      })
    },
    [userCompanyLabel],
  )

  if (!get(downloadLinks, activeSource)) {
    console.warn('Link to download app are not set.')
    return null
  }

  return (
    <Dialog open={showDownloadMobileDialog} onClose={closeDialogHandle}>
      <DialogTitle close={isMobile() ? closeDialogHandle : null} showLogo>
        {isMobile() ? (
          <Trans
            i18nKey={'downloadMobileApp'}
            defaults={
              'Download {{company}} mobile App in <strong>{{storeName}}</strong> or with <strong>Android APK</strong> file:'
            }
            values={{ company: userCompanyLabel, storeName: storeName() }}
          />
        ) : downloadOption === 'androidApk' ? (
          <Trans
            i18nKey={'scanToDownloadMobileAppFromStore'}
            defaults={'Scan to download {{company}} mobile App in <strong>App Store or Google Play</strong>:'}
            values={{ company: userCompanyLabel }}
          />
        ) : (
          <Trans
            i18nKey={'scanToDownloadMobileApk'}
            defaults={'Scan to download {{company}} mobile App with <strong>Android APK</strong> file:'}
            values={{ company: userCompanyLabel }}
          />
        )}
      </DialogTitle>

      <DialogContent>
        {isMobile() ? (
          <DownloadButtonsWrap>
            <a href={get(downloadLinks, 'googlePlayStore')} onClick={clickDownloadButtonHandle}>
              <DownloadFromGooglePlayIcon />
            </a>
            <a href={get(downloadLinks, 'androidApkFile')} onClick={clickDownloadButtonHandle}>
              <DownloadAndroidApkIcon />
              Android APK
            </a>
          </DownloadButtonsWrap>
        ) : (
          <>
            <QRCodeWrap>
              <QRCode
                size={180}
                value={get(downloadLinks, activeSource) as MobileAppLinksIndices}
                fgColor={theme.palette.mode === 'dark' ? '#fff' : '#1F1F1F'}
                bgColor={theme.palette.mode === 'dark' ? '#1F1F1F' : '#fff'}
              />
            </QRCodeWrap>

            <DividerWrap>
              <DividerLine />
              <DividerText>or</DividerText>
            </DividerWrap>

            <DownloadButton
              key={downloadButton[downloadOption].sourceKey}
              active={downloadButton[downloadOption].sourceKey === activeSource}
              translationKey={downloadButton[downloadOption].translationKey}
              defaultTranslation={downloadButton[downloadOption].defaultTranslation}
              data-key={downloadButton[downloadOption].sourceKey}
              onClick={clickDownloadButtonHandle}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default DownloadMobileAppDialog
