import React, { FC } from 'react'
import { styled } from '@mui/system'
import { DialogTitle as MuiDialogTitle } from '@mui/material'
import { DialogTitleProps } from '@mui/material/DialogTitle'

import { Logo } from '../../Logo'
import { isMobile } from '../../../utils'
import { CloseButton } from '../../CloseButton'

const DialogTitleWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& img': {
    width: 180,
    height: 'auto',
    marginTop: 48,
  },
  '& h2': {
    padding: 0,
    fontSize: 16,
    fontWeight: 400,
  },
  '& button': {
    position: 'absolute',
    top: 18,
    right: 18,
  },
})

export const DialogTitle: FC<DialogTitleProps & { close: (() => void) | null; showLogo?: boolean }> = ({
  close,
  showLogo,
  children,
  ...rest
}) => (
  <DialogTitleWrap>
    {showLogo && isMobile() && <Logo variant={'header'} />}
    <MuiDialogTitle {...rest}>{children}</MuiDialogTitle>
    {close && <CloseButton onClose={close} />}
  </DialogTitleWrap>
)
