import i18next from 'i18next'
import get from 'lodash/get'
import includes from 'lodash/includes'

import { accountTypes, companies, whiteLabels } from '@bdswiss/common-enums'

import { MenuItem } from './types'
import { Account } from '../../store/types'
import { createAffiliateSingleSignOnLink, createIBSingleSignOnLink } from '../../services/graphql/client'
import { isIos, openUrlHandle } from '../../utils'
import { setShowDownloadMobileAppDialog } from '../../store/actions'
import { trackEvent } from '../../analytics/firebaseLogger'
import { bentoMenuItemClick } from '../../analytics/events'
import store from '../../store'
import config, { MobileAppLinksIndices } from '../../config'

export const isAffiliateAccount = (accountType: Account['__typename']): boolean => {
  return [
    accountTypes.AffiliateAccount.key,
    accountTypes.AffiliateVanuatuAccount.key,
    accountTypes.AffiliateMauritiusAccount.key,
  ].includes(accountType)
}

export const isIBAccount = (accountType: Account['__typename']): boolean => {
  return [
    accountTypes.IntroducingBrokerAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsAccount.key,
    accountTypes.IntroducingBrokerMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsVanuatuAccount.key,
    accountTypes.IntroducingBrokerBDSwissVanuatuAccount.key,
  ].includes(accountType)
}

export const openUrl = (pathname = ''): void => {
  window.open(`${config.get('dashboardUrl')}${pathname}`, '_blank')
}

const createNewTabWithAffiliateOrIBAccountUrl = (): Window | null => {
  const windowProxy = window.open('about:blank', '_blank')
  windowProxy?.document.write(`<h4>${i18next.t('bentoMenuPleaseWait', 'Please wait')}</h4>`)

  return windowProxy
}

export const isViverno = (): boolean => includes(document.referrer || window.location.href, 'viverno')

export const getDownloadAppLinks = (): Record<MobileAppLinksIndices, string | undefined> => config.get('mobileAppLinks')

export const getMenuItemsForClient = ({
  accounts,
  company,
  whiteLabel,
}: {
  accounts: Account[]
  whiteLabel: string
  company: string
}): Array<MenuItem> => {
  const menuItems: Array<MenuItem> = [
    {
      id: 'Dashboard',
      title: {
        translationKey: 'bentoMenuDashboardTitle',
        defaultTranslation: 'Dashboard',
      },
      handle: () => {
        trackEvent(bentoMenuItemClick, { BentoMenuItem: 'Dashboard' })
        openUrl('/dashboard')
      },
    },
    {
      id: 'Website',
      title: {
        translationKey: 'bentoMenuWebsiteTitle',
        defaultTranslation: 'Website',
      },
      handle: () => {
        const domain =
          config.get('websiteUrl')?.replace(/https?:\/\//, '') ||
          (whiteLabel
            ? get(whiteLabels, [whiteLabel, 'domain'])
            : get(companies, [company, 'entity', 'value']) === 'mau'
            ? 'mau.bdswiss.com'
            : get(companies, [company, 'domain']) || 'bdswiss.com')

        trackEvent(bentoMenuItemClick, { BentoMenuItem: 'Website' })
        return window.open(`${window.location.protocol}//${domain}/`)
      },
    },
    {
      id: 'Divider',
      title: {
        translationKey: '-',
        defaultTranslation: '-',
      },
    },
    {
      id: 'TradingCentral',
      title: {
        translationKey: 'bentoMenuTradingCentralTitle',
        defaultTranslation: 'Trading central',
      },
      subtitle: {
        translationKey: 'bentoMenuTradingCentralSubtitle',
        defaultTranslation: 'Automated investment analytics & analyst research',
      },
      handle: () => {
        trackEvent(bentoMenuItemClick, { BentoMenuItem: 'Trade Central' })
        openUrl('/trading-tools/tradingCentral')
      },
    },
  ]

  const telegramChannelLink = config.get('socialMediaLinks')?.telegram
  if (telegramChannelLink) {
    menuItems.splice(-1, 0, {
      id: 'TelegramChannel',
      title: {
        translationKey: 'bentoMenuTelegramChannelTitle',
        defaultTranslation: 'Telegram channel',
      },
      subtitle: {
        translationKey: 'bentoMenuTelegramChannelSubtitle',
        defaultTranslation: 'Market news, trading alerts & opportunities',
      },
      handle: () => {
        trackEvent(bentoMenuItemClick, { BentoMenuItem: 'Telegram Channel' })
        window.open(telegramChannelLink, '_blank')
      },
    })
  }

  const downloadLinks = getDownloadAppLinks()
  if (Object.values(downloadLinks).length) {
    menuItems.push({
      id: 'MobileApp',
      title: {
        translationKey: 'bentoMenuMobileApplicationTitle',
        translationValues: { company: get(companies, [company || 'bdSwissMauritius', 'brandLabel'], 'BDSwiss') },
        defaultTranslation: '{{company}} mobile app',
      },
      subtitle: {
        translationKey: 'bentoMenuMobileApplicationSubtitle',
        defaultTranslation: 'Scan to download App iOS & Android',
      },
      handle: () => {
        handleDownloadMobileApp()
        trackEvent(bentoMenuItemClick, { BentoMenuItem: 'Mobile App' })
      },
    })
  }

  const ibAccount = accounts?.find((account) => isIBAccount(account?.__typename))
  if (ibAccount) {
    menuItems.splice(2, 0, {
      id: 'IBPortal',
      title: {
        translationKey: 'bentoMenuIBPortalTitle',
        defaultTranslation: 'IB Portal',
      },
      handle: () => {
        const accountId = ibAccount?.id
        if (accountId) {
          createIBSingleSignOnLink({ accountId })
            .then((response) => {
              const link = get(response, ['data', 'data', 'link'])
              if (link) {
                const newTabWindow = createNewTabWithAffiliateOrIBAccountUrl()
                newTabWindow && (newTabWindow.location.href = link)
              }
            })
            .catch(({ message }) => {
              console.warn(`An error occurred while creating a link for an IB account: ${message}`)
            })
        } else {
          console.warn('IB account was not found.')
        }

        trackEvent(bentoMenuItemClick, { BentoMenuItem: 'IB portal' })
      },
    })
  }

  const affiliateAccount = accounts?.find((account) => isAffiliateAccount(account?.__typename))
  if (affiliateAccount) {
    menuItems.splice(1, 0, {
      id: 'AffiliatePortal',
      title: {
        translationKey: 'bentoMenuAffiliatePortalTitle',
        defaultTranslation: 'Affiliate Portal',
      },
      handle: () => {
        const accountId = affiliateAccount?.id
        if (accountId) {
          createAffiliateSingleSignOnLink({ accountId })
            .then((response) => {
              const link = get(response, ['data', 'data', 'link'])
              if (link) {
                const newTabWindow = createNewTabWithAffiliateOrIBAccountUrl()
                newTabWindow && (newTabWindow.location.href = link)
              }
            })
            .catch(({ message }) => {
              console.warn(`An error occurred while creating a link for an affiliate account: ${message}`)
            })
        } else {
          console.warn('Affiliate account was not found.')
        }

        trackEvent(bentoMenuItemClick, { BentoMenuItem: 'Affiliate portal' })
      },
    })
  }

  return menuItems
}

export const handleDownloadMobileApp = (): void => {
  const { appleAppStore } = getDownloadAppLinks()
  switch (true) {
    case appleAppStore && isIos():
      openUrlHandle(appleAppStore as string)
      break
    default:
      store?.dispatch(setShowDownloadMobileAppDialog(true))
  }
}
